import React from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  styled,
} from "@mui/material";

import { Button, CardActionArea } from "gatsby-theme-material-ui";

import CardTag from "./project-card-tag";

interface ProjectCardProps {
  name?: string;
  shortDescription?: string;
  assetFolder?: string;
  tags?: Array<string>;
  permalink?: string;
}

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto auto",
  borderTopStyle: "solid",
  borderTopWidth: 1,
  borderTopColor: theme.palette.divider,
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  backgroundPosition: `left`,
  height: 300,
}));

const TagContainer = styled(`div`)(({ theme }) => ({
  justifySelf: "end",
  alignSelf: "center",
  gridColumnStart: "2",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  justifySelf: "start",
  alignSelf: "center",
}));

interface ProjectCardContentProps {
  name?: string;
  shortDescription?: string;
}

const ProjectCardContent: React.FC<ProjectCardContentProps> = ({
  name,
  shortDescription,
}) => {
  return (
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        {name}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {shortDescription}
      </Typography>
    </CardContent>
  );
};

const ProjectCard: React.FC<ProjectCardProps> = ({
  name,
  assetFolder,
  shortDescription,
  tags,
  permalink,
}) => {
  //const imageUrl = `/images/projects/${assetFolder}/tile.png`;

  return (
    <Card>
      {assetFolder && (
        <CardActionArea to={permalink}>
          <StyledCardMedia image={assetFolder} title={name} />
          <ProjectCardContent name={name} shortDescription={shortDescription} />
        </CardActionArea>
      )}
      {!assetFolder && (
        <ProjectCardContent name={name} shortDescription={shortDescription} />
      )}
      <StyledCardActions>
        {assetFolder && (
          <StyledButton size="small" color="primary" to={permalink}>
            Learn More
          </StyledButton>
        )}
        {tags && (
          <TagContainer>
            {tags.map((tag, i) => {
              if (tag) {
                return <CardTag key={i} tag={tag} />;
              }
              return <></>;
            })}
          </TagContainer>
        )}
      </StyledCardActions>
    </Card>
  );
};

export default ProjectCard;
