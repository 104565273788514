import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";

import { CardContent, Card, CardHeader, styled } from "@mui/material";
import { ProjectsQuery } from "../../graphql-types";
import ProjectCard from "../components/project-card";

export const query = graphql`
  query Projects {
    allProjectsYaml {
      group(field: category) {
        edges {
          node {
            asset_folder
            name
            short_description
            tags
            permalink
          }
        }
        fieldValue
      }
    }
    allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
      edges {
        node {
          relativePath
          publicURL
        }
      }
    }
  }
`;

const StyledCategoryCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  borderBottomStyle: "solid",
  borderBottomWidth: 1,
  borderBottomColor: theme.palette.divider,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const StyledContent = styled(`div`)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridGap: theme.spacing(3),
}));

interface CategoryProps {
  children?: React.ReactNode;
  name?: string;
}

const Category: React.FC<CategoryProps> = ({ children, name }) => {
  return (
    <StyledCategoryCard>
      <StyledCardHeader title={name} />
      <CardContent>
        <StyledContent>{children}</StyledContent>
      </CardContent>
    </StyledCategoryCard>
  );
};

interface ProjectsPageProps {
  data: ProjectsQuery;
}

const tileLookup = new Map();

const tileImage = (
  edges: Array<{
    node: { relativePath: string; publicURL?: string | null | undefined };
  }>,
  assetFolder: string,
): string => {
  if (assetFolder === "") {
    return "";
  }

  if (tileLookup.has(assetFolder)) {
    return tileLookup.get(assetFolder);
  }
  const ret = edges.find(
    ({ node }) => node.relativePath === `projects/${assetFolder}/tile.png`,
  );

  if (ret) {
    const url = ret.node.publicURL;
    if (!url) {
      return "";
    }
    tileLookup.set(assetFolder, url);
    return url;
  }
  return "";
};

const ProjectsPage: React.FC<ProjectsPageProps> = () => {
  const data = useStaticQuery<ProjectsQuery>(query);
  const categoryGroups = data.allProjectsYaml.group;

  return (
    <Layout pageTitle="Projects">
      {categoryGroups.map((value, index: number) => {
        if (value) {
          const projects = value.edges.map((x) => {
            return x.node;
          });
          return (
            <Category key={index} name={value.fieldValue ?? ""}>
              {projects.map((project, i) => {
                if (project) {
                  return (
                    <div key={i}>
                      <ProjectCard
                        name={project.name ?? ""}
                        assetFolder={
                          tileImage(
                            data.allFile.edges,
                            project.asset_folder ?? "",
                          ) ?? ""
                        }
                        shortDescription={project.short_description ?? ""}
                        tags={
                          project.tags ? (project.tags as Array<string>) : []
                        }
                        permalink={project.permalink ?? ""}
                      />
                    </div>
                  );
                }
                return <></>;
              })}
            </Category>
          );
        }

        return <></>;
      })}
    </Layout>
  );
};

export default ProjectsPage;
