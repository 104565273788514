import React from "react";
import { Chip, Avatar } from "@mui/material";

import GitHubIcon from "@mui/icons-material/GitHub";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  query TagImageQuery {
    digipen: file(relativePath: { eq: "digipen.svg" }) {
      publicURL
    }
  }
`;

interface CardTagProps {
  tag: string;
}

const CardTag: React.FC<CardTagProps> = ({ tag }) => {
  const data = useStaticQuery(query);

  let avatar: React.ReactElement = <></>;

  switch (tag) {
    case `DigiPen`:
      avatar = <Avatar alt="DigiPen" src={data.digipen.publicURL} />;
      break;
    case `GitHub`:
      avatar = <GitHubIcon />;
  }

  return <Chip avatar={avatar} label={tag} variant="outlined" />;
};

export default CardTag;
